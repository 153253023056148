const isProduction =
  import.meta.env.VITE_NODE_ENV === 'production' ||
  import.meta.env.MODE === 'production'

const debug = (message: any, value: any = null, type: any = 'log') => {
  if (isProduction) return

  // @ts-ignore
  console[type](message, value)
}

export default debug
