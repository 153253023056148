<script setup lang="ts">
import { ref, computed, type PropType } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { REMOVE_NOTIFICATION_FROM_ARRAY } from '@/store/mutation-types'
import InfoBox from '@/components/generic/InfoBox.vue'
import { dateToShortString } from '@/helpers/formats'
import type { Notification } from '@/types'

const props = defineProps({
  notification: {
    type: Object as PropType<Notification>,
    default: null,
  },
})

const emit = defineEmits(['close'])

const store = useStore()
const router = useRouter()
const route = useRoute()

const feedMenu = ref(false)
const charityId = route.params.id

const severityClass = computed(() => props.notification.severity)
const notificationTime = computed(() => {
  const time = dateToShortString(props.notification.time)

  if (time === 'Invalid DateTime') {
    return ''
  }

  return time
})

const toggleFeedMenu = () => {
  feedMenu.value = !feedMenu.value
}

const remove = async () => {
  await store.dispatch('deleteNotification', {
    charityId: charityId,
    id: props.notification.id,
  })

  emit('close')
}

const sendTo = () => {
  if (!feedMenu.value) {
    const route = props.notification.category
    switch (route) {
      case 'donationSources':
        router.push({
          name: 'DonationSources',
          params: { id: charityId },
        })
        break
      case 'declaration':
        router.push(`/charity/${charityId}/declarations`)
        break
      case 'support':
        if (props.notification.url) {
          window.open(props.notification.url, '_blank')
        }
        break
      case 'settings':
        router.push(`/charity/${charityId}/settings`)
        break
      case 'billing':
        router.push(`/charity/${charityId}/billing`)
        break
      case 'link':
        window.open(props.notification.url, '_blank')
        break
    }
    emit('close')
  }
}
</script>

<template>
  <article :class="`feed-item--${severityClass}`" class="feed-item">
    <section @click="sendTo" class="cursor-pointer">
      <h6 class="text-left">{{ notification.message }}</h6>
      <p class="mb-0">{{ notification.description }}</p>
      <p
        v-if="notificationTime !== ''"
        :class="severityClass"
        class="feed-item__time-now mt-3"
        :aria-label="$t('Generic.DashNavBar.FeedItem.PostedTimeAriaLabel')"
        data-testid="notificationTime"
      >
        {{ $t('Generic.DashNavBar.FeedItem.Posted') }}
        {{ notificationTime }}
      </p>
    </section>
    <nav
      v-if="notification.severity !== 'error' || notification.url"
      :class="severityClass"
      class="menu-dots feed-item__menu-dots"
      data-testid="notification-menu"
      :aria-label="$t('Generic.DashNavBar.FeedItem.MenuAriaLabel')"
      @click="toggleFeedMenu"
    >
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </nav>

    <transition name="menu-fade">
      <InfoBox
        v-if="
          (feedMenu && notification.severity !== 'error') ||
          (feedMenu && notification.url)
        "
        data-testid="feedMenu"
        @close="toggleFeedMenu"
      >
        <template #content>
          <div
            v-if="notification.severity !== 'error'"
            data-testid="feedMenuRemove"
            class="menu-link"
            @click="remove"
          >
            <a href="#" class="modal-title">
              {{ $t('Generic.DashNavBar.FeedItem.RemoveButton') }}
            </a>
          </div>
          <div
            v-if="notification.url"
            class="menu-link"
            data-testid="feedMenuLearn"
          >
            <a :href="notification.url" class="modal-title">
              {{ $t('Generic.DashNavBar.FeedItem.LearnMoreButton') }}
            </a>
          </div>
        </template>
      </InfoBox>
    </transition>
  </article>
</template>

<style lang="scss" scoped>
.feed-item {
  position: relative;
  padding: var(--spacer);
  border-bottom: solid 1px var(--light-grey);
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__time-now {
    font-size: 60%;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
  }

  &__menu-dots {
    display: flex;
    cursor: pointer;
    position: relative;
    height: 30px;
    width: 40px;
    transition: background-color 0.2s;
    align-items: center;
    padding: 5px;
    top: -5px;
    right: -5px;
    border-radius: 5px;

    &:hover {
      background-color: rgba(var(--black-rgb), 0.035);
    }

    .dot {
      $dot-size: 5px;
      background-color: var(--black);
      width: $dot-size;
      height: $dot-size;
      border-radius: 100%;
      margin: $dot-size * 0.5;
      transition: background-color 0.2s;
    }
  }

  &--error {
    background-color: var(--bs-danger-bg-subtle);
  }
}

.info-box-wrapper {
  top: 50px;
  right: 15px;
  width: auto;
}

a {
  text-decoration: none;
  font-weight: 600;
  color: var(--black);
  font-size: 20px;
}
</style>
