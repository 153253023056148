import type { Commit } from 'vuex'
import api from '@/store/api/charity'
import {
  SET_TOTAL_DECLARATIONS,
  SET_TOTAL_GIFT_AID,
  SET_TOTAL_DONATIONS,
  SET_CLAIMS,
  SET_DECLARATIONS,
  SET_CLAIM_SUMMARY,
  SET_DONATION_SOURCES,
  SET_USER,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_CHARITY,
  SET_CHARITY_ID,
  SET_CHARITY_NAME,
  SET_NOTIFICATIONS,
  REMOVE_NOTIFICATION_FROM_ARRAY,
} from '@/store/mutation-types'
import type { UserInformation, NomineeAssignment } from '@/types'

export default {
  // eslint-disable-next-line no-empty-pattern
  async deleteNotification(
    { commit }: { commit: Commit },
    payload: { charityId: string; id: string }
  ) {
    try {
      const response = await api.deleteNotification(payload)
      commit(REMOVE_NOTIFICATION_FROM_ARRAY, payload.id)
      return response
    } catch (error) {
      console.error('An error occurred deleting a notification')
    }
  },

  async fetchNextPage(
    { commit }: { commit: Commit },
    payload: { charityId: string; pageNumber: number }
  ) {
    try {
      const response = await api.fetchNextPage(payload)
      commit(SET_DECLARATIONS, response.data)
      return response
    } catch (error) {
      console.error(error)
    }
  },

  async fetchCharitySummary({ commit }: { commit: Commit }, payload: string) {
    try {
      const response = await api.fetchCharitySummary(payload)
      commit(SET_TOTAL_DECLARATIONS, response.data.countDeclarations)
      commit(SET_TOTAL_GIFT_AID, response.data.totalGiftAid)
      commit(SET_TOTAL_DONATIONS, response.data.totalDonations)
      commit(SET_CLAIMS, response.data.claims)
      commit(SET_DONATION_SOURCES, response.data.donationSources)
      commit(SET_CHARITY_NAME, response.data.name)
      commit(SET_NOTIFICATIONS, response.data.notifications)
      return response
    } catch (error) {
      console.error('An error occurred fetching charity summary')
    }
  },

  async fetchClaim(
    { commit }: { commit: Commit },
    payload: {
      charityId: string
      claimId: string
      perPage: number
      pageNumber: number
    }
  ) {
    try {
      const response = await api.fetchClaim(payload)
      commit(SET_CLAIM_SUMMARY, response.data.summary)
      commit(SET_DECLARATIONS, response.data.declarations)
      return response
    } catch (error) {
      console.error('An error occurred fetching claim data')
    }
  },

  async fetchClaimCsvBreakdown(
    _: any,
    payload: { charityId: string; claimId: string }
  ) {
    try {
      return await api.fetchClaimCsvBreakdown(payload)
    } catch (error) {
      throw new Error(
        'An error occurred downloading the CSV file, please try again.'
      )
    }
  },

  clearClaim({ commit }: { commit: Commit }) {
    commit(SET_DECLARATIONS, {})
    commit(SET_CLAIM_SUMMARY, {})
  },

  async fetchCurrentUser({ commit }: { commit: Commit }) {
    try {
      const response = await api.fetchCurrentUser()
      commit(SET_USER, response.data)
      return response
    } catch (error) {
      console.error('An error occurred fetching the current user')
    }
  },

  async postUserDetails(
    { commit }: { commit: Commit },
    payload: UserInformation
  ) {
    try {
      const response = await api.postUserDetails(payload)
      commit(SET_FIRST_NAME, payload.firstname)
      commit(SET_LAST_NAME, payload.lastname)
      return response
    } catch (error) {
      console.error('An error occurred posting user details')
    }
  },

  async putUserDetails(
    { commit }: { commit: Commit },
    payload: UserInformation
  ) {
    try {
      const response = await api.putUserDetails(payload)
      commit(SET_FIRST_NAME, payload.firstname)
      commit(SET_LAST_NAME, payload.lastname)
      return response
    } catch (error) {
      console.error('An error occurred putting user details')
    }
  },

  async postCharityDetails(
    { commit }: { commit: Commit },
    payload: NomineeAssignment
  ) {
    try {
      const response = await api.postCharityDetails(payload)
      commit(SET_CHARITY, { id: response.data.charityId })
      return response
    } catch (error) {
      console.error('An error occurred posting charity details')
    }
  },

  async patchSourceFriendlyName(
    _: any,
    {
      friendlyName,
      charityId,
      donationSourceId,
    }: { friendlyName: string; charityId: string; donationSourceId: string }
  ) {
    try {
      return await api.patchSource(
        { friendlyName },
        charityId,
        donationSourceId
      )
    } catch (error) {
      console.error('An error occurred patching source friendly name')
    }
  },

  async patchSourceEndDate(
    _: any,
    {
      until,
      charityId,
      donationSourceId,
    }: { until: string; charityId: string; donationSourceId: string }
  ) {
    try {
      return await api.patchSource({ until }, charityId, donationSourceId)
    } catch (error) {
      console.error('An error occurred patching source end date')
    }
  },

  setCharityId({ commit }: { commit: Commit }, payload: string) {
    commit(SET_CHARITY_ID, payload)
  },
}
